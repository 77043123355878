import { datadogLogs } from '@datadog/browser-logs'
import { datadogRum } from '@datadog/browser-rum'
import { envVars } from '../../envVars'
import { User } from '@datadog/browser-core'

const allowedEnvs = ['production', 'staging']

const env = envVars.APP_ENV as string
const applicationId = envVars.DATADOG_APPLICATION_ID as string
const clientToken = envVars.DATADOG_CLIENT_TOKEN as string
const service = 'payroll-salary-advance-frontend'

function startLogs() {
  if (!allowedEnvs.includes(env)) {
    return
  }

  datadogLogs.init({
    clientToken,
    service,
    env,
    version: service,
    site: 'datadoghq.com',
    sessionSampleRate: 70,
  })
}

function startMonitoring() {
  if (!allowedEnvs.includes(env)) {
    return
  }

  datadogRum.init({
    applicationId,
    clientToken,
    site: 'datadoghq.com',
    service,
    env,
    version: service,
    sessionSampleRate: 10,
    sessionReplaySampleRate: 1,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
  })

  datadogRum.startSessionReplayRecording()
}

function setUserContext(user: User) {
  if (datadogRum.getInternalContext()) {
    datadogRum.setUser(user)
  }
}

export { startLogs, startMonitoring, setUserContext }
