import { startLogs, startMonitoring } from '../../utils'

startMonitoring()
startLogs()

import React from 'react'
import ReactDOM from 'react-dom'
import singleSpaReact from 'single-spa-react'
import { App as rootComponent } from './App'
import { Modules } from '../../enums/Modules'

const reactLifecycles = singleSpaReact({
  React,
  ReactDOM,
  rootComponent,
  domElementGetter: () =>
    document.querySelector(
      `[data-app="${Modules.COMMON_APP_NAME}"]`,
    ) as Element,
})

const bootstrap = [reactLifecycles.bootstrap]

const mount = [reactLifecycles.mount]

const unmount = [reactLifecycles.unmount]

export { bootstrap, mount, unmount }
